import { FC } from 'react';

import type { PriceRendering } from '@sitecore/types/Price';
import { Price as SparkyPrice } from '@sparky';
import type { PriceProps } from '@sparky/types';

export const Price: FC<PriceRendering> = ({ fields, params }) => {
  if (!fields) {
    return null;
  }

  return (
    <SparkyPrice
      discountPrice={fields.discountPriceText.value}
      discountPriceAriaText={fields.discountPriceAriaText.value}
      originalPrice={fields.originalPriceText.value}
      originalPriceAriaText={fields.originalPriceAriaText.value}
      text={fields.text?.value}
      size={params?.size ? (params.size as PriceProps['size']) : 'M'}
      suffixText={fields?.suffixText?.value}
    />
  );
};

export default Price;
